.record_result h2 {
    padding-bottom: 30px;
}

.record_result {
    /* padding: 30px; */
    margin: auto;
    font-size: large;
}
.conversation-container {
    list-style: none;
}

.Doctor, .Patient {
    color: white;
    word-wrap: break-word;
    padding: 20px;
    border-radius: 0.2em;
    font-size: larger;
    margin-bottom: 10px;
}

.Doctor {
    background-color: #458FF6;
    text-align: left;
    margin-right: 60%;
}

.Patient {
    background-color: #d6e1f9;
    text-align: right;
    margin-left: 60%;
    color: black;
    
}

.DoctorSpeak {
    text-align: left;
}

.PatientSpeak {
    text-align: right;
}

.next-button-container {
    margin-top: 10%;
}