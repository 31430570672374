h2 {
	/* padding:20px; */
}

.recording-container {
	border: 1px solid blue;
}

.recording-state-desc {
	border: 1px solid red;
	height: 400px;
}

img {
	width: 50%;
	height: 50%;
	left: 0;
	text-align: left;
	padding: 20px;
}

.btn-start-recording {
	color: white;
	width: 271px;
	height: 56px;
	font-weight: 700;
	align-items: center;
	margin: 20px;
	background: linear-gradient(
		90deg,
		rgba(103, 195, 243, 1) 0%,
		rgba(90, 152, 242, 1) 100%
	);
}

.buttonlist-play-stop-cancel {
	/* display: flex;
    flex-direction: row; */
	border: 1px solid green;
	list-style: none;
}

button {
	background-color: white;
	border: none;
	cursor: pointer;
}

.icon-image {
	border: none;
	color: white;
	/* background-color: white; */
}

.w-full {
	width: 100%;
}

.flex {
	display: flex;
}

.items-center {
	align-items: center;
}

.justify-center {
	justify-content: center;
}

.cursor-pointer {
	cursor: pointer;
}
