/* Login.module.css */

.loginContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
}

.loginForm {
	display: flex;
	flex-direction: column;
	width: 300px;
	padding: 20px;
	border: 1px solid #ccc;
	border-radius: 5px;
	background-color: #fff;
}

.inputField {
	margin-bottom: 10px;
	padding: 10px;
	border: 1px solid #ccc;
	border-radius: 4px;
}

.submitButton {
	padding: 10px;
	border: none;
	border-radius: 4px;
	background-color: #4caf50;
	color: #fff;
	cursor: pointer;
}
