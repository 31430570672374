.btn-start-recording {
    border: 1px solid #67C3F3;
    background: linear-gradient(208.18deg, #67C3F3 9.05%, #5A98F2 76.74%);
    height: 50px;
    width: 200px;
    border-radius: 0.4em;
    font-size: x-large;
}

.btn_gap {
    justify-content: space-between;
}

.btn-next, .btn-fulltranscript{
    border: 1px solid #67C3F3;
    background: linear-gradient(208.18deg, #67C3F3 9.05%, #5A98F2 76.74%);    width: 120px;
    padding: 10px;
    border-radius: 0.2em;
    font-size: larger;
    color: white;
    width: 140px;
    margin: 0 0 0 89%;
}

.btn-fulltranscript, .btn-sendtoEHR {
    border: 1px solid #8167f3;
    background: linear-gradient(208.18deg, #67C3F3 9.05%, #5A98F2 76.74%);    width: 120px;
    border-radius: 0.2em;
    font-size: larger;
    color: white;   
    width: 200px;
}
.btn-fulltranscript {
    margin-right: 20px;
}