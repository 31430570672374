.main-container {
    /* margin: 5%; */
    padding: 10%;
    /* border: 1px solid blue; */
    position: relative;
    height: 150px;
}

p {
    padding-bottom: 40px;
    line-height: 2em;
    word-wrap: break-word;
}

.btn-face-to-face {
	background: linear-gradient(90deg, rgba(103, 195, 243, 1.00) 0.0%, rgba(90, 152, 242, 1.00) 100.0%);
    width: 214px;
    height: 56px;
    font-size:x-large;
    border-radius: 8px;
    color: white;
}

.Frame_Frame {
    /* border: 1px solid red; */
	/* position: relative; */
	/* margin: auto; */
    top: 20px;
    align-items: center;
}



